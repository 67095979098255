import React from 'react'
import './allies.css';
import allieimg from '../../assets/belzonat.png'
import geb from '../../assets/grupo.png'


export const Allies = () => {
  return (
    <div>
  
      <div className='okana__allie section__padding' id='allies'>
          <div className='okana__allie-image'>
              <img src={ allieimg } alt='allie'></img>
          </div>
          <div className='okana__allie-content'>
              
              <h1 className='gradient__text'>Nuestra misión ha sido superar las necesidades y expectativas de nuestros clientes</h1>
              <p>La tecnología de Belzona puede dar respuesta a una amplia variedad de áreas de reparación, desde sistemas preconfigurados 
                  completos hasta reparaciones simples en el lugar. Belzona proporciona un servicio global 
                  al sector OIL & GAS y a la industria en general.</p>
              <h4><a href='https://www.belzona.com/es/index.aspx' target='_blank' rel='noopener noreferrer'>Visítanos</a></h4>
          </div>
      </div>
    
    
      <div className='okana__allie section__padding' id='allies'>
          <div className='okana__allie-image'>
              <img src={ geb } alt='allie'></img>
          </div>
          <div className='okana__allie-content'>
              
              <h1 className='gradient__text'>Nuestros clientes han podido ver los resultados de la excelencia</h1>
              <p>nos enorgullece haber demostrado nuestra excelencia en cada
                 interacción con GEB. A través de soluciones innovadoras, un 
                 enfoque centrado en la calidad y un compromiso constante con 
                 los resultados, hemos forjado una relación basada en la confianza 
                 y el éxito mutuo. Este logro refleja nuestra dedicación a superar 
                 las expectativas y ofrecer un valor tangible a nuestros clientes.</p>
              <h4><a href='https://www.grupoenergiabogota.com' target='_blank' rel='noopener noreferrer'>Visítanos</a></h4>
          </div>
      </div>
  

    </div>
    
  )
}

export default Allies